import React, { useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import { PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'

import theme from 'src/lib/theme'
import { PageContext } from 'src/types'
import Layout from 'src/components/Layout/Layout'
import StreamsPricingPlanSelector from 'src/sections/StreamsPricingPlanSelector/StreamsPricingPlanSelector'
import PageContainer from 'src/components/PageContainer/PageContainer'
import { trackStreamsWebPromotion } from 'src/lib/tracking'

const PricingPlansPage: React.FC<PageProps<unknown, PageContext>> = ({
  pageContext,
}) => {
  const t = useTranslate()

  useEffect(() => {
    trackStreamsWebPromotion('redirected-to', 'pricing-plans')
  }, [])

  return (
    <Layout
      pageContext={pageContext}
      background={theme.namedColors.streamsBackground}
      hideSiteHeader
      hideSiteFooter
    >
      <Helmet
        title={t('Pricing Plans')}
        meta={[
          {
            name: 'robots',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <PageContainer>
        <StreamsPricingPlanSelector />
      </PageContainer>
    </Layout>
  )
}

export default PricingPlansPage
