import React, { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex } from '@chatterbug/aaron'

import CurrencyToggle from 'src/components/CurrencyToggle/CurrencyToggle'
import { useStreamsPricingPlans } from 'src/data/pricing/plans'
import ProductList from 'src/components/ProductList/ProductList'
import ProductCardForStreamPricingPlan from 'src/components/ProductList/subcomponents/ProductCard/ProductCardForStreamPricingPlan'
import StreamsLogo from 'src/components/StreamsLogo/StreamsLogo'
import { Currency } from 'src/types'

const StreamsPricingPlanSelector: React.FC = () => {
  const t = useTranslate()
  const items = useStreamsPricingPlans()

  const [showError, setShowError] = useState(false)
  const [currency, setCurrency] = useState<Currency>(Currency.eur)
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pt={80}
    >
      {/* logo and title */}
      <StreamsLogo height={57} iconOnly />
      <Box
        as="h3"
        variant="text.h3"
        py="3x"
        color="black0"
        maxWidth={576}
        textAlign="center"
      >
        {t(
          'This discount is no longer available. To continue select a subscription from below.'
        )}
      </Box>
      <Box
        as="p"
        variant="text.paragraph"
        color="black0"
        textAlign="center"
        mb={{ _: 0, tablet: '5x' }}
      >
        {t(
          'Get access to daily live streams, quizzes, videos on demand and more! Cancel Anytime.'
        )}
      </Box>
      {showError && (
        <Box
          as="p"
          color="red68"
          mb={{ _: 0, tablet: '3x' }}
          dangerouslySetInnerHTML={{
            __html: t(
              'Something went wrong while selecting the subscription plan. Please contact our <a href="mailto:help@chatterbug.com" class="link">customer support</a>.'
            ),
          }}
        />
      )}
      {/* currency changer on small screens */}
      <Box display={{ _: 'block', tablet: 'none' }} mt="6x" mb="4x">
        <CurrencyToggle onSelect={setCurrency} selectedCurrency={currency} />
      </Box>
      {/* pricing Plans */}
      <Box
        display="grid"
        width={{
          mobile: '100%',
          tablet: 'max-content',
        }}
      >
        <ProductList
          items={items}
          ItemComponent={ProductCardForStreamPricingPlan}
          currency={currency}
          showButton
          onError={() => setShowError(true)}
        />
      </Box>
      {/* currency changer on big screens */}
      <Box display={{ _: 'none', tablet: 'block' }} mt="6x">
        <CurrencyToggle onSelect={setCurrency} selectedCurrency={currency} />
      </Box>
    </Flex>
  )
}

export default StreamsPricingPlanSelector
