import React from 'react'
import { Box, Flex } from '@chatterbug/aaron'

import { Currency } from 'src/types'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'

export type Props = {
  onSelect: (currency: Currency) => void
  selectedCurrency: Currency
}

const CurrencyToggler: React.FC<Props> = ({ onSelect, selectedCurrency }) => {
  return (
    <Flex>
      <Box mr="2x">
        <Button
          data-testid="currency-button"
          variant={
            selectedCurrency === Currency.eur
              ? BUTTON_VARIANTS.secondaryOutlineHighlighted
              : BUTTON_VARIANTS.secondaryOutline
          }
          label="€ euro"
          href={null}
          onClick={() => onSelect(Currency.eur)}
        />
      </Box>
      <Button
        data-testid="currency-button"
        className="currency-button-usd"
        variant={
          selectedCurrency === Currency.usd
            ? BUTTON_VARIANTS.secondaryOutlineHighlighted
            : BUTTON_VARIANTS.secondaryOutline
        }
        label="$ dollars"
        href={null}
        onClick={() => onSelect(Currency.usd)}
      />
    </Flex>
  )
}

export default CurrencyToggler
